import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getReq, postReq } from "../../configs/ApiUrl.js";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";
import { MdMenu, MdClose } from "react-icons/md";
import { ReactComponent as History } from "../../icons/History-Icon.svg";
import { ReactComponent as Logout } from "../../icons/Logout-Icon.svg";
import { ReactComponent as Sell } from "../../icons/Coin-Icon-Outline.svg";
import { ReactComponent as Delivery } from "../../icons/Delivery-Icon.svg";
import { ReactComponent as Profile } from "../../icons/User-Circle-Icon.svg";

import EditDetails from "../pages/UserContact";
import ActiveOrders from "../pages/ApprovedDelivery.js";
import OrderHistory from "../pages/UserBuy";
import SellingHistory from "../pages/UserSell";

function UserProfile() {
  const { t } = useTranslation(["userProfile"]);
  const navigateLogout = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [activeComponent, setActiveComponent] = useState("editDetails");
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const userId = localStorage.getItem("userId");
      if (!userId) return;
      try {
        const userResp = await getReq(`/user/${userId}`);
        setUserInfo(userResp);
      } catch (e) {
        console.error(e);
      }
    };
    fetchUser();
  }, []);

  const handleLogOut = async () => {
    setLoading(true);
    const logOutResp = await postReq("/user/logout");
    if (logOutResp?.success) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      navigateLogout("/welcome-login");
    }
    setLoading(false);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "editDetails":
        return <EditDetails />;
      case "activeOrders":
        return <ActiveOrders />;
      case "orderHistory":
        return <OrderHistory />;
      case "sellingHistory":
        return <SellingHistory />;
      default:
        return <EditDetails />;
    }
  };

  return (
    <div className='flex flex-col md:flex-row min-h-screen h-full'>
      {/* Page Header */}
      <PageHeader />

      {/* Hamburger Icon for Mobile */}
      <button
        onClick={() => setSidebarOpen(!isSidebarOpen)}
        className={`fixed top-24 z-30 bg-cromboOrange text-white p-1 rounded-md shadow-md md:hidden transition-all duration-300 ${
          isSidebarOpen ? "left-52" : "left-2"
        }`}>
        {isSidebarOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
      </button>

      {/* Sidebar */}
      <aside
        className={`bg-gray-50 w-64 p-6 border-r flex flex-col md:h-screen h-full md:static fixed md:w-1/6 z-20 transform pt-24 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 md:translate-x-0`}>
        <div className='flex items-center justify-between mb-6'>
          <h2 className='text-lg font-bold pt-1'>Account Settings</h2>
        </div>
        <nav className='flex flex-col gap-8 flex-1'>
          <button
            onClick={() => setActiveComponent("editDetails")}
            className={`flex items-center font-semibold hover:text-cromboOrange text-sm ${
              activeComponent === "editDetails" ? "text-cromboOrange" : ""
            }`}>
            <Profile className='mr-2 w-7 h-7' /> {t("contactInfoButton")}
          </button>
          <button
            onClick={() => setActiveComponent("activeOrders")}
            className={`flex items-center font-semibold hover:text-cromboOrange text-sm ${
              activeComponent === "activeOrders" ? "text-cromboOrange" : ""
            }`}>
            <Delivery className='mr-2 w-7 h-7' />{" "}
            {t("approvedDeliveryButton")}
          </button>
          <button
            onClick={() => setActiveComponent("orderHistory")}
            className={`flex items-center font-semibold hover:text-cromboOrange text-sm ${
              activeComponent === "orderHistory" ? "text-cromboOrange" : ""
            }`}>
            <History className='mr-2 w-7 h-7' /> {t("productsBoughtButton")}
          </button>
          <button
            onClick={() => setActiveComponent("sellingHistory")}
            className={`flex items-center font-semibold hover:text-cromboOrange text-sm ${
              activeComponent === "sellingHistory" ? "text-cromboOrange" : ""
            }`}>
            <Sell className='mr-2 w-7 h-7' /> {t("productSaleButton")}
          </button>
          <button
            onClick={handleLogOut}
            className='flex items-center text-red-600 font-semibold hover:text-red-800 mt-auto'>
            <Logout className='w-10 h-10 mr-2' /> {t("logoutButton")}
          </button>
        </nav>
      </aside>

      {/* Main Section */}
      <section className='flex-1 p-12 flex flex-col bg-white min-h-screen'>
        {renderActiveComponent()}
      </section>

      {/* Mobile Footer */}
      <div className='block md:hidden'>
        <PageFooter />
      </div>
    </div>
  );
}

export default UserProfile;
