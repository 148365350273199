import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/pages/util/LanguageSelect";
import { ReactComponent as Buy } from "../icons/Store-Icon.svg";
import { ReactComponent as Sell } from "../icons/Coin-Icon-Outline.svg";
import { ReactComponent as Favourite } from "../icons/Heart-Icon-Outline.svg";
import { ReactComponent as ShoppingCart } from "../icons/Shopping Cart Icon.svg";
import { ReactComponent as UserProfile } from "../icons/User-Circle-Icon.svg";

export default function PageHeader() {
  const { t } = useTranslation(["altText", "homepage"]);
  const location = useLocation();

  // Helper function to check if the current route is active
  const isActive = (path) => location.pathname === path;

  return (
    <header className='fixed top-0 left-0 right-0 bg-cromboGray flex justify-between items-center px-4 py-2 shadow-md z-50 h-16'>
      <Link
        to='/shopping-home'
        className='h-full'>
        <img
          alt={t("logo")}
          src='./../assets/images/24Mobler.png'
          className='h-full object-contain'
        />
      </Link>
      <div className='flex items-center space-x-6'>
        {/* Icons and labels visible only on desktop */}
        <div className='hidden md:flex items-center justify-evenly w-full space-x-12'>
          <Link
            to='/shopping-home'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/shopping-home")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}>
            <Buy />
            <span className='text-md font-bold'>{t("homepage:buyButton")}</span>
          </Link>
          <Link
            to='/selling-add-furniture'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/selling-add-furniture")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}>
            <Sell />
            <span className='text-md font-bold'>
              {t("homepage:sellButton")}
            </span>
          </Link>
          <Link
            to='/catalogue-favourites'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/catalogue-favourites")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}>
            <Favourite />
            <span className='text-md font-bold'>
              {t("homepage:favouriteButton")}
            </span>
          </Link>
          <Link
            to='/catalogue-cart'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/catalogue-cart")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}>
            <ShoppingCart />
            <span className='text-md font-bold'>
              {t("homepage:cartButton")}
            </span>
          </Link>
          <Link
            to='/user-profile'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/user-profile") ? "text-cromboOrangeFade" : "text-white"
            }`}>
            <UserProfile />
            <span className='text-md font-bold'>
              {t("homepage:profileButton")}
            </span>
          </Link>
        </div>
        {/* Profile icon visible only on mobile */}
        <div className='flex items-center space-x-2 md:space-x-6'>
        <Link
          to='/user-profile'
          className='text-white md:hidden'>
          <UserProfile />
        </Link>
        <LanguageSelect />
      </div>
      </div>
    </header>
  );
}
